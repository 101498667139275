@font-face {
  font-family: "One Icons Filled";
  font-style: normal;
  font-weight: 400;
  src: url("./one-icons-filled.eot?ed4b5be4533daa48a50ec56791405f30?#iefix") format("embedded-opentype"),
url("./one-icons-filled.woff2?ed4b5be4533daa48a50ec56791405f30") format("woff2"),
url("./one-icons-filled.woff?ed4b5be4533daa48a50ec56791405f30") format("woff"),
url("./one-icons-filled.ttf?ed4b5be4533daa48a50ec56791405f30") format("truetype"),
url("./one-icons-filled.svg?ed4b5be4533daa48a50ec56791405f30#one-icons-filled") format("svg");
}

.one-icons-filled {
  font-family: "One Icons Filled";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: pre;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  
}
