@font-face {
  font-family: "One Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("./one-icons-outlined.eot?ae2b39bdd052b70c8e0bd55eff02e378?#iefix") format("embedded-opentype"),
url("./one-icons-outlined.woff2?ae2b39bdd052b70c8e0bd55eff02e378") format("woff2"),
url("./one-icons-outlined.woff?ae2b39bdd052b70c8e0bd55eff02e378") format("woff"),
url("./one-icons-outlined.ttf?ae2b39bdd052b70c8e0bd55eff02e378") format("truetype"),
url("./one-icons-outlined.svg?ae2b39bdd052b70c8e0bd55eff02e378#one-icons-outlined") format("svg");
}

.one-icons-outlined {
  font-family: "One Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: pre;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  
}
