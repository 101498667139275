:root {
--one-border-radius-0: 0;
--one-border-radius-4: 4px;
--one-border-radius-8: 8px;
--one-border-radius-12: 12px;
--one-border-radius-16: 16px;
--one-border-radius-20: 20px;
--one-border-radius-24: 24px;
--one-border-radius-28: 28px;
--one-border-radius-32: 32px;
--one-border-radius-circle: 50%;
--one-border-width-0: 0px;
--one-border-width-2: 2px;
--one-border-width-4: 4px;
--one-border-style-none: none;
--one-border-style-hidden: hidden;
--one-border-style-dotted: dotted;
--one-border-style-dashed: dashed;
--one-border-style-solid: solid;
--one-border-style-double: double;
--one-border-style-groove: groove;
--one-border-style-ridge: ridge;
--one-border-style-inset: inset;
--one-border-style-outset: outset;
--one-border-none: none;
--one-box-shadow-inset: inset;
--one-box-shadow-none: none;
--one-box-shadow-positive-0: 0px;
--one-box-shadow-positive-1: 1px;
--one-box-shadow-positive-2: 2px;
--one-box-shadow-positive-3: 3px;
--one-box-shadow-positive-4: 4px;
--one-box-shadow-negative-0: 0px;
--one-box-shadow-negative-1: -1px;
--one-box-shadow-negative-2: -2px;
--one-box-shadow-negative-3: -3px;
--one-box-shadow-negative-4: -4px;
--one-color-accent-white: #ffffff;
--one-color-accent-black: #000000;
--one-elevation-box-shadow-0: none;
--one-elevation-box-shadow-1: 0px 1px 3px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-2: 0px 2px 4px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-3: 0px 3px 4px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-4: 0px 4px 4px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-5: 0px 5px 5px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-6: 0px 6px 6px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-7: 0px 7px 7px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-8: 0px 8px 8px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-9: 0px 9px 9px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-10: 0px 10px 10px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-11: 0px 11px 11px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-12: 0px 12px 12px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-13: 0px 13px 13px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-14: 0px 14px 14px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-15: 0px 15px 15px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-16: 0px 16px 16px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-17: 0px 17px 17px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-18: 0px 18px 18px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-19: 0px 19px 19px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-20: 0px 20px 20px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-21: 0px 21px 21px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-22: 0px 22px 22px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-23: 0px 23px 23px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-24: 0px 24px 24px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-1: 0px -1px 3px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-negative-2: 0px -2px 4px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-negative-3: 0px -3px 4px rgba(0, 0, 0, 0.4);
--one-elevation-box-shadow-negative-4: 0px -4px 4px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-5: 0px -5px 5px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-6: 0px -6px 6px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-7: 0px -7px 7px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-8: 0px -8px 8px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-9: 0px -9px 9px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-10: 0px -10px 10px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-11: 0px -11px 11px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-12: 0px -12px 12px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-13: 0px -13px 13px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-14: 0px -14px 14px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-15: 0px -15px 15px rgba(0, 0, 0, 0.35);
--one-elevation-box-shadow-negative-16: 0px -16px 16px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-17: 0px -17px 17px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-18: 0px -18px 18px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-19: 0px -19px 19px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-20: 0px -20px 20px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-21: 0px -21px 21px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-22: 0px -22px 22px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-23: 0px -23px 23px rgba(0, 0, 0, 0.3);
--one-elevation-box-shadow-negative-24: 0px -24px 24px rgba(0, 0, 0, 0.3);
--one-media-breakpoint-xs-min: 0px;
--one-media-breakpoint-xs-max: 319px;
--one-media-breakpoint-s-min: 320px;
--one-media-breakpoint-s-max: 719px;
--one-media-breakpoint-m-min: 720px;
--one-media-breakpoint-m-max: 1279px;
--one-media-breakpoint-l-min: 1280px;
--one-media-breakpoint-l-max: 1919px;
--one-media-breakpoint-xl-min: 1920px;
--one-media-breakpoint-xl-max: 99999999px;
--one-sizer-base-0: 0;
--one-sizer-base-42: 20px;
--one-sizer-base-75: 36px;
--one-sizer-base-100: 48px;
--one-sizer-base-133: 64px;
--one-sizer-base-150: 72px;
--one-sizer-base-200: 96px;
--one-sizer-base-250: 120px;
--one-sizer-base-300: 144px;
--one-sizer-base-400: 192px;
--one-sizer-base-600: 288px;
--one-sizer-base-730: 350px;
--one-sizer-base-2500: 1200px;
--one-sizer-base-100-p: 100%;
--one-sizer-base-33-p: 33%;
--one-spacer-px-0: 0px;
--one-spacer-px-4: 4px;
--one-spacer-px-6: 6px;
--one-spacer-px-8: 8px;
--one-spacer-px-12: 12px;
--one-spacer-px-16: 16px;
--one-spacer-px-20: 20px;
--one-spacer-px-24: 24px;
--one-spacer-px-28: 28px;
--one-spacer-px-32: 32px;
--one-spacer-px-36: 36px;
--one-spacer-px-40: 40px;
--one-spacer-px-44: 44px;
--one-spacer-px-48: 48px;
--one-spacer-px-52: 52px;
--one-spacer-px-56: 56px;
--one-spacer-px-60: 60px;
--one-spacer-px-64: 64px;
--one-spacer-px-68: 68px;
--one-spacer-px-72: 72px;
--one-spacer-px-76: 76px;
--one-spacer-px-80: 80px;
--one-spacer-px-84: 84px;
--one-spacer-px-88: 88px;
--one-spacer-px-92: 92px;
--one-spacer-px-96: 96px;
--one-spacer-px-100: 100px;
--one-spacer-rem-0: 0rem;
--one-spacer-rem-1: 1rem;
--one-spacer-rem-2: 2rem;
--one-spacer-rem-3: 3rem;
--one-spacer-rem-4: 4rem;
--one-spacer-rem-5: 5rem;
--one-spacer-rem-6: 6rem;
--one-spacer-rem-0-25: 0.25rem;
--one-spacer-rem-0-375: 0.375rem;
--one-spacer-rem-0-5: 0.5rem;
--one-spacer-rem-0-75: 0.75rem;
--one-spacer-rem-1-25: 1.25rem;
--one-spacer-rem-1-5: 1.5rem;
--one-spacer-rem-1-75: 1.75rem;
--one-spacer-rem-2-25: 2.25rem;
--one-spacer-rem-2-5: 2.5rem;
--one-spacer-rem-2-75: 2.75rem;
--one-spacer-rem-3-25: 3.25rem;
--one-spacer-rem-3-5: 3.5rem;
--one-spacer-rem-3-75: 3.75rem;
--one-spacer-rem-4-25: 4.25rem;
--one-spacer-rem-4-5: 4.5rem;
--one-spacer-rem-4-75: 4.75rem;
--one-spacer-rem-5-25: 5.25rem;
--one-spacer-rem-5-5: 5.5rem;
--one-spacer-rem-5-75: 5.75rem;
--one-spacing-base-1: 0.25rem;
--one-spacing-base-2: 0.5rem;
--one-spacing-base-3: 0.75rem;
--one-spacing-base-4: 1rem;
--one-spacing-base-5: 1.25rem;
--one-spacing-base-6: 1.5rem;
--one-spacing-base-7: 1.75rem;
--one-spacing-base-8: 2rem;
--one-spacing-base-9: 2.25rem;
--one-spacing-base-10: 2.5rem;
--one-spacing-base-11: 2.75rem;
--one-spacing-base-12: 3rem;
--one-spacing-base-13: 3.25rem;
--one-spacing-base-14: 3.5rem;
--one-spacing-base-15: 3.75rem;
--one-spacing-base-16: 4rem;
--one-spacing-base-17: 4.25rem;
--one-spacing-base-18: 4.5rem;
--one-spacing-base-19: 4.75rem;
--one-spacing-base-20: 5rem;
--one-spacing-base-21: 5.25rem;
--one-spacing-base-22: 5.5rem;
--one-spacing-base-23: 5.75rem;
--one-spacing-base-24: 6rem;
--one-spacing-base-25: 6.25rem;
--one-spacing-base-26: 6.5rem;
--one-spacing-base-27: 6.75rem;
--one-spacing-base-28: 7rem;
--one-spacing-base-29: 7.25rem;
--one-spacing-base-30: 7.5rem;
--one-spacing-base-31: 7.75rem;
--one-spacing-base-32: 8rem;
--one-spacing-base-33: 8.25rem;
--one-spacing-base-34: 8.5rem;
--one-spacing-base-35: 8.75rem;
--one-spacing-base-36: 9rem;
--one-spacing-base-37: 9.25rem;
--one-spacing-base-38: 9.5rem;
--one-spacing-base-39: 9.75rem;
--one-spacing-base-40: 10rem;
--one-spacing-base-41: 10.25rem;
--one-spacing-base-42: 10.5rem;
--one-spacing-base-43: 10.75rem;
--one-spacing-base-44: 11rem;
--one-spacing-base-45: 11.25rem;
--one-spacing-base-46: 11.5rem;
--one-spacing-base-47: 11.75rem;
--one-spacing-base-48: 12rem;
--one-spacing-base-none: 0;
--one-spacing-base-0-1: 0.0625rem;
--one-spacing-base-0-2: 0.125rem;
--one-spacing-radius-100: 1000rem;
--one-text-font-family-default: Roboto, "Helvetica Neue", sans-serif;
--one-text-font-family-roboto: Roboto, "Helvetica Neue", sans-serif;
--one-text-font-family-roche-sans: "Roche Sans", sans-serif;
--one-text-font-family-roche-serif: "Roche Serif", serif;
--one-text-font-weight-300: 300;
--one-text-font-weight-400: 400;
--one-text-font-weight-500: 500;
--one-text-font-weight-700: 700;
--one-text-font-size-px-12: 12px;
--one-text-font-size-px-14: 14px;
--one-text-font-size-px-16: 16px;
--one-text-font-size-px-18: 18px;
--one-text-font-size-px-20: 20px;
--one-text-font-size-px-24: 24px;
--one-text-font-size-px-32: 32px;
--one-text-font-size-px-34: 34px;
--one-text-font-size-px-40: 40px;
--one-text-font-size-px-48: 48px;
--one-text-font-size-px-56: 56px;
--one-text-font-size-px-60: 60px;
--one-text-font-size-px-64: 64px;
--one-text-font-size-px-88: 88px;
--one-text-font-size-px-96: 96px;
--one-text-font-size-rem-1: 1rem;
--one-text-font-size-rem-2: 2rem;
--one-text-font-size-rem-3: 3rem;
--one-text-font-size-rem-4: 4rem;
--one-text-font-size-rem-6: 6rem;
--one-text-font-size-rem-0-625: 0.625rem;
--one-text-font-size-rem-0-75: 0.75rem;
--one-text-font-size-rem-0-875: 0.875rem;
--one-text-font-size-rem-1-125: 1.125rem;
--one-text-font-size-rem-1-25: 1.25rem;
--one-text-font-size-rem-1-5: 1.5rem;
--one-text-font-size-rem-1-75: 1.75rem;
--one-text-font-size-rem-2-125: 2.125rem;
--one-text-font-size-rem-2-5: 2.5rem;
--one-text-font-size-rem-3-5: 3.5rem;
--one-text-font-size-rem-3-75: 3.75rem;
--one-text-font-size-rem-5-5: 5.5rem;
--one-text-font-size-base-1: 0.625rem;
--one-text-font-size-base-2: 0.75rem;
--one-text-font-size-base-3: 0.875rem;
--one-text-font-size-base-4: 1rem;
--one-text-font-size-base-5: 1.125rem;
--one-text-font-size-base-6: 1.25rem;
--one-text-font-size-base-7: 1.375rem;
--one-text-font-size-base-8: 1.5rem;
--one-text-font-size-base-9: 1.625rem;
--one-text-font-size-base-10: 1.75rem;
--one-text-font-size-base-11: 1.875rem;
--one-text-font-size-base-12: 2rem;
--one-text-font-size-base-13: 2.125rem;
--one-text-font-size-base-14: 2.25rem;
--one-text-font-size-base-15: 2.375rem;
--one-text-font-size-base-16: 2.5rem;
--one-text-font-size-base-17: 2.75rem;
--one-text-font-size-base-18: 3rem;
--one-text-font-size-base-19: 3.5rem;
--one-text-font-size-base-20: 3.75rem;
--one-text-font-size-base-21: 4rem;
--one-text-font-size-base-22: 4.5rem;
--one-text-font-size-base-23: 5rem;
--one-text-font-size-base-24: 5.5rem;
--one-text-font-size-base-25: 6rem;
--one-text-line-height-100: 1;
--one-text-line-height-117: 1.17;
--one-text-line-height-120: 1.2;
--one-text-line-height-125: 1.25;
--one-text-line-height-130: 1.3;
--one-text-line-height-135: 1.35;
--one-text-line-height-140: 1.4;
--one-text-line-height-148: 1.48;
--one-text-line-height-150: 1.5;
--one-text-line-height-160: 1.6;
--one-text-line-height-165: 1.65;
--one-text-line-height-title: 1.32;
--one-text-line-height-body: 1.2;
--one-text-line-height-caption: 1.17;
--one-text-line-height-rem-1: 1rem;
--one-text-line-height-rem-2: 2rem;
--one-text-line-height-rem-3: 3rem;
--one-text-line-height-rem-4: 4rem;
--one-text-line-height-rem-1-25: 1.25rem;
--one-text-line-height-rem-1-375: 1.375rem;
--one-text-line-height-rem-1-5: 1.5rem;
--one-text-line-height-rem-1-625: 1.625rem;
--one-text-line-height-rem-1-75: 1.75rem;
--one-text-line-height-rem-2-125: 2.125rem;
--one-text-line-height-rem-2-25: 2.25rem;
--one-text-line-height-rem-2-375: 2.375rem;
--one-text-line-height-rem-2-625: 2.625rem;
--one-text-line-height-rem-2-75: 2.75rem;
--one-text-line-height-rem-2-875: 2.875rem;
--one-text-line-height-rem-3-25: 3.25rem;
--one-text-line-height-rem-3-75: 3.75rem;
--one-text-line-height-rem-4-2: 4.2rem;
--one-text-line-height-rem-4-5: 4.5rem;
--one-text-line-height-rem-4-625: 4.625rem;
--one-text-line-height-rem-6-625: 6.625rem;
--one-text-line-height-rem-7-5: 7.5rem;
--one-text-line-height-base-1: 1rem;
--one-text-line-height-base-2: 1.125rem;
--one-text-line-height-base-3: 1.25rem;
--one-text-line-height-base-4: 1.375rem;
--one-text-line-height-base-5: 1.5rem;
--one-text-line-height-base-6: 1.625rem;
--one-text-line-height-base-7: 1.75rem;
--one-text-line-height-base-8: 1.875rem;
--one-text-line-height-base-9: 2rem;
--one-text-line-height-base-10: 2.125rem;
--one-text-line-height-base-11: 2.375rem;
--one-text-line-height-base-12: 2.5rem;
--one-text-line-height-base-13: 2.625rem;
--one-text-line-height-base-14: 2.75rem;
--one-text-line-height-base-15: 2.875rem;
--one-text-line-height-base-16: 3rem;
--one-text-line-height-base-17: 3.5rem;
--one-text-line-height-base-18: 3.75rem;
--one-text-line-height-base-19: 4rem;
--one-text-line-height-base-20: 4.5rem;
--one-text-line-height-base-21: 5rem;
--one-text-line-height-base-22: 6rem;
--one-text-line-height-base-23: 6.5rem;
--one-text-line-height-base-24: 7.5rem;
--one-text-decoration-line-underline: underline;
--one-text-decoration-line-none: none;
--one-text-font-kerning-auto: auto;
--one-text-font-kerning-normal: normal;
--one-text-font-kerning-none: none;
--one-text-font-kerning-px-1-5: 1.5px;
--one-text-font-kerning-rem-0-09375: 0.09375rem;
--one-text-font-kerning-negative-px-1-5: -1.5px;
--one-text-font-kerning-negative-rem-0-09375: -0.09375rem;
--one-z-index-footer: 1000;
--one-z-index-header: 10101;
--one-z-index-backdrop: 10102;
--one-z-index-workflow-overlay: 100102;
--one-z-index-tooltip: 100103;
--one-z-index-popover: 100103;
--one-z-index-notification: 99999999;
--one-z-index-cookie-acceptance: 99999999;
--one-color-chart-blue-100: #bde3ff;
--one-color-chart-blue-200: #79bcfd;
--one-color-chart-blue-300: #4ba2fc;
--one-color-chart-blue-400: #1482fa;
--one-color-chart-blue-500: #0f5ee1;
--one-color-chart-blue-600: #0b41cd;
--one-color-chart-blue-700: #073298;
--one-color-chart-blue-800: #022366;
--one-color-blue-50: #f5f9ff;
--one-color-blue-100: #daedfb;
--one-color-blue-200: #bde3ff;
--one-color-blue-300: #8ccfff;
--one-color-blue-400: #5e94ed;
--one-color-blue-500: #1482fa;
--one-color-blue-600: #0b41cd;
--one-color-blue-700: #1942a3;
--one-color-blue-800: #022366;
--one-color-blue-900: #071227;
--one-color-gray-0: #121212;
--one-color-gray-50: #f5f5f2;
--one-color-gray-100: #ddd9d5;
--one-color-gray-200: #c2bab5;
--one-color-gray-300: #969391;
--one-color-gray-400: #868281;
--one-color-gray-500: #706b69;
--one-color-gray-600: #544f4f;
--one-color-gray-700: #3e3f41;
--one-color-gray-800: #262627;
--one-color-gray-900: #121212;
--one-color-neutral-100: #fff7f5;
--one-color-neutral-200: #ffe8de;
--one-color-neutral-300: #fad6c7;
--one-color-neutral-400: #fac9b5;
--one-color-neutral-500: #f0b69e;
--one-color-neutral-600: #d8ab97;
--one-color-neutral-700: #be9476;
--one-color-neutral-800: #86521d;
--one-color-neutral-900: #5d3921;
--one-color-subbrand-100: #f5ffec;
--one-color-subbrand-200: #e7ffd2;
--one-color-subbrand-250: #dbffbd;
--one-color-subbrand-300: #b5ff78;
--one-color-subbrand-400: #79e22d;
--one-color-subbrand-500: #49b900;
--one-color-subbrand-600: #0e6c00;
--one-color-subbrand-700: #0b5700;
--one-color-subbrand-800: #094700;
--one-color-green-100: #e7f3ea;
--one-color-green-200: #cee7d8;
--one-color-green-300: #9dceb6;
--one-color-green-400: #54aa81;
--one-color-green-500: #00874a;
--one-color-green-600: #005f34;
--one-color-green-700: #004425;
--one-color-green-800: #00361e;
--one-color-green-900: #001b0f;
--one-color-yellow-100: #fff9e8;
--one-color-yellow-200: #ffedb9;
--one-color-yellow-250: #ffe28a;
--one-color-yellow-300: #ffd043;
--one-color-yellow-400: #ffc414;
--one-color-yellow-500: #9b5400;
--one-color-yellow-600: #67370a;
--one-color-yellow-700: #391c00;
--one-color-yellow-800: #1d0e00;
--one-color-orange-100: #fff1e0;
--one-color-orange-200: #ffddb3;
--one-color-orange-250: #ffbd69;
--one-color-orange-300: #ff9b5a;
--one-color-orange-400: #ff7d29;
--one-color-orange-500: #fd6423;
--one-color-orange-600: #ed4a0d;
--one-color-orange-700: #b22b0d;
--one-color-orange-800: #8e1c02;
--one-color-red-100: #ffdde1;
--one-color-red-200: #f6b7be;
--one-color-red-250: #e68098;
--one-color-red-300: #db4d67;
--one-color-red-400: #cc0033;
--one-color-red-500: #a30014;
--one-color-red-600: #7a000f;
--one-color-red-700: #52000f;
--one-color-red-800: #29000a;
--one-color-magenta-100: #eaadd8;
--one-color-magenta-200: #e87dc4;
--one-color-magenta-300: #e557b3;
--one-color-magenta-400: #e13ea2;
--one-color-magenta-500: #dd2391;
--one-color-magenta-600: #d60078;
--one-color-magenta-700: #cb0174;
--one-color-magenta-800: #b2046b;
--one-color-purple-100: #f2d4ff;
--one-color-purple-200: #ebb4fe;
--one-color-purple-300: #e085fc;
--one-color-purple-400: #d151fe;
--one-color-purple-500: #bc36f0;
--one-color-purple-600: #a320d3;
--one-color-purple-700: #8a0baa;
--one-color-purple-800: #7d0096;
--one-generic-background-transparent: transparent;
--one-gradient-colored-roche-sky-to-sand: linear-gradient(121deg, var(--one-color-blue-200) -17.31%, var(--one-color-neutral-100) 46.37%, var(--one-color-background-base) 65.87%);
--one-gradient-colored-roche-heaven-to-sky: linear-gradient(136deg, var(--one-color-background-base) 35.41%, var(--one-color-neutral-100) 58%, var(--one-color-blue-200) 128.14%);
--one-gradient-experimental-roche-rosy-horizon: linear-gradient(137deg, var(--one-color-purple-100) -31.02%, var(--one-color-background-base) 56.1%);
--one-gradient-experimental-roche-blushing-sunset: linear-gradient(145deg, var(--one-color-background-base) 54.87%, var(--one-color-purple-100) 227.96%);
--one-gradient-experimental-roche-sunburst-glow: linear-gradient(145deg, var(--one-color-background-base) 54.87%, var(--one-color-orange-250) 227.96%);
--one-gradient-experimental-roche-fiery-flare: linear-gradient(145deg, var(--one-color-background-base) 54.87%, var(--one-color-red-250) 227.96%);
--one-gradient-experimental-roche-misty-sky: linear-gradient(143deg, var(--one-color-gray-50) 39.09%, var(--one-color-blue-200) 227.7%);
--one-gradient-experimental-roche-roseate-fog: linear-gradient(145deg, var(--one-color-gray-50) 54.87%, var(--one-color-purple-100) 227.96%);
--one-gradient-experimental-roche-golden-haze: linear-gradient(145deg, var(--one-color-gray-50) 54.87%, var(--one-color-orange-250) 227.96%);
--one-gradient-experimental-roche-blazing-dawn: linear-gradient(145deg, var(--one-color-gray-50) 54.87%, var(--one-color-red-250) 227.96%);
--one-gradient-experimental-roche-glimmering-peach: linear-gradient(119deg, rgba(255, 255, 255, 0.00) 43.26%, rgba(250, 214, 199, 0.40) 77.7%);
--one-gradient-experimental-roche-peach-sky: linear-gradient(149deg, rgba(255, 255, 255, 0.00) -29.38%, rgba(250, 214, 199, 0.40) 69.97%, #0B41CD 141.56%);
--one-gradient-experimental-roche-sky-bright: linear-gradient(115deg, var(--one-color-blue-200) -83.47%, var(--one-color-background-base) 64.92%);
--one-gradient-experimental-navify-sunlit-leaf: linear-gradient(115deg, var(--one-color-subbrand-300) -83.47%, var(--one-color-background-base) 64.92%);
--one-gradient-experimental-navify-forest-glow: linear-gradient(149deg, rgba(255, 255, 255, 0.00) -29.38%, rgba(181, 255, 120, 0.30) 69.97%, #0E6C00 141.56%);
--one-gradient-experimental-navify-forest-crisp: linear-gradient(137deg, var(--one-color-subbrand-100) 23.48%, var(--one-color-subbrand-250) 80.08%);
--one-gradient-grey-roche-snowy: linear-gradient(148deg, var(--one-color-background-base) 54.06%, var(--one-color-gray-50) 146.91%);
--one-gradient-grey-roche-snow-cream: linear-gradient(148deg, var(--one-color-background-base) 54.06%, var(--one-color-gray-100) 146.91%);
--one-gradient-grey-roche-snow-stone: linear-gradient(148deg, var(--one-color-background-base) 54.06%, var(--one-color-gray-200) 146.91%);
--one-gradient-grey-roche-mossy-hues: linear-gradient(140deg, var(--one-color-gray-50) 32.98%, var(--one-color-gray-100) 101.15%);
--one-gradient-grey-roche-foggy-dawn: linear-gradient(135deg, var(--one-color-gray-100) -8.78%, var(--one-color-gray-50) 103.14%);
--one-gradient-grey-roche-morning-mist: linear-gradient(146deg, var(--one-color-gray-50) -3.1%, var(--one-color-background-base) 108.05%);
--one-gradient-grey-roche-blinding-light: linear-gradient(145deg, var(--one-color-gray-100) -7.36%, var(--one-color-background-base) 94.93%);
--one-gradient-grey-roche-radiant-glow: linear-gradient(145deg, var(--one-color-gray-200) -32.29%, var(--one-color-background-base) 77.95%);
--one-gradient-neutral-roche-peachy-dawn: linear-gradient(141deg, var(--one-color-neutral-300) -68.64%, var(--one-color-background-base) 52.89%);
--one-gradient-neutral-roche-warm-sunset: linear-gradient(145deg, var(--one-color-background-base) 54.87%, var(--one-color-neutral-400) 227.96%);
--one-gradient-neutral-roche-warm-haze: linear-gradient(145deg, var(--one-color-gray-50) 54.87%, var(--one-color-neutral-400) 227.96%);
--one-icon-family-filled: filled;
--one-icon-family-outlined: outlined;
--one-backdrop-opacity: 0.7;
--one-banner-color-secondary-warning-icon: var(--one-color-gray-900);
--one-banner-color-secondary-alert-icon: var(--one-color-accent-white);
--one-footer-color-global-background: var(--one-gradient-colored-roche-heaven-to-sky);
--one-color-chart-diverging-positive-negative-red-100: var(--one-color-red-100);
--one-color-chart-diverging-positive-negative-red-200: var(--one-color-red-200);
--one-color-chart-diverging-positive-negative-red-300: var(--one-color-red-300);
--one-color-chart-diverging-positive-negative-red-400: var(--one-color-red-400);
--one-color-chart-diverging-positive-negative-red-500: var(--one-color-red-500);
--one-color-chart-diverging-positive-negative-red-600: var(--one-color-red-600);
--one-color-chart-diverging-positive-negative-red-700: var(--one-color-red-700);
--one-color-chart-diverging-positive-negative-red-800: var(--one-color-red-800);
--one-color-chart-diverging-positive-negative-green-200: var(--one-color-green-200);
--one-color-chart-diverging-positive-negative-green-300: var(--one-color-green-300);
--one-color-chart-diverging-positive-negative-green-400: var(--one-color-green-400);
--one-color-chart-diverging-positive-negative-green-500: var(--one-color-green-500);
--one-color-chart-diverging-positive-negative-green-600: var(--one-color-green-600);
--one-color-chart-diverging-positive-negative-green-700: var(--one-color-green-700);
--one-color-chart-diverging-positive-negative-green-800: var(--one-color-green-800);
--one-color-chart-diverging-positive-negative-green-900: var(--one-color-green-900);
--one-color-chart-diverging-hot-cold-orange-100: var(--one-color-orange-100);
--one-color-chart-diverging-hot-cold-orange-200: var(--one-color-orange-200);
--one-color-chart-diverging-hot-cold-orange-300: var(--one-color-orange-300);
--one-color-chart-diverging-hot-cold-orange-400: var(--one-color-orange-400);
--one-color-chart-diverging-hot-cold-orange-500: var(--one-color-orange-500);
--one-color-chart-diverging-hot-cold-orange-600: var(--one-color-orange-600);
--one-color-chart-diverging-hot-cold-orange-700: var(--one-color-orange-700);
--one-color-chart-diverging-hot-cold-orange-800: var(--one-color-orange-800);
--one-color-chart-diverging-hot-cold-brand-100: var(--one-color-chart-blue-100);
--one-color-chart-diverging-hot-cold-brand-200: var(--one-color-chart-blue-200);
--one-color-chart-diverging-hot-cold-brand-300: var(--one-color-chart-blue-300);
--one-color-chart-diverging-hot-cold-brand-400: var(--one-color-chart-blue-400);
--one-color-chart-diverging-hot-cold-brand-500: var(--one-color-chart-blue-500);
--one-color-chart-diverging-hot-cold-brand-600: var(--one-color-chart-blue-600);
--one-color-chart-diverging-hot-cold-brand-700: var(--one-color-chart-blue-700);
--one-color-chart-diverging-hot-cold-brand-800: var(--one-color-chart-blue-800);
--one-color-chart-sequential-brand-100: var(--one-color-chart-blue-100);
--one-color-chart-sequential-brand-200: var(--one-color-chart-blue-200);
--one-color-chart-sequential-brand-300: var(--one-color-chart-blue-300);
--one-color-chart-sequential-brand-400: var(--one-color-chart-blue-400);
--one-color-chart-sequential-brand-500: var(--one-color-chart-blue-500);
--one-color-chart-sequential-brand-600: var(--one-color-chart-blue-600);
--one-color-chart-sequential-brand-700: var(--one-color-chart-blue-700);
--one-color-chart-sequential-brand-800: var(--one-color-chart-blue-800);
--one-color-chart-sequential-subbrand-100: var(--one-color-subbrand-100);
--one-color-chart-sequential-subbrand-200: var(--one-color-subbrand-200);
--one-color-chart-sequential-subbrand-300: var(--one-color-subbrand-300);
--one-color-chart-sequential-subbrand-400: var(--one-color-subbrand-400);
--one-color-chart-sequential-subbrand-500: var(--one-color-subbrand-500);
--one-color-chart-sequential-subbrand-600: var(--one-color-subbrand-600);
--one-color-chart-sequential-subbrand-700: var(--one-color-subbrand-700);
--one-color-chart-sequential-subbrand-800: var(--one-color-subbrand-800);
--one-color-chart-sequential-purple-100: var(--one-color-purple-100);
--one-color-chart-sequential-purple-200: var(--one-color-purple-200);
--one-color-chart-sequential-purple-300: var(--one-color-purple-300);
--one-color-chart-sequential-purple-400: var(--one-color-purple-400);
--one-color-chart-sequential-purple-500: var(--one-color-purple-500);
--one-color-chart-sequential-purple-600: var(--one-color-purple-600);
--one-color-chart-sequential-purple-700: var(--one-color-purple-700);
--one-color-chart-sequential-purple-800: var(--one-color-purple-800);
--one-color-chart-sequential-orange-100: var(--one-color-orange-100);
--one-color-chart-sequential-orange-200: var(--one-color-orange-200);
--one-color-chart-sequential-orange-300: var(--one-color-orange-300);
--one-color-chart-sequential-orange-400: var(--one-color-orange-400);
--one-color-chart-sequential-orange-500: var(--one-color-orange-500);
--one-color-chart-sequential-orange-600: var(--one-color-orange-600);
--one-color-chart-sequential-orange-700: var(--one-color-orange-700);
--one-color-chart-sequential-orange-800: var(--one-color-orange-800);
--one-color-chart-qualitative-subbrand-medium: var(--one-color-subbrand-600);
--one-color-chart-qualitative-subbrand-high: var(--one-color-subbrand-800);
--one-color-chart-qualitative-orange-medium: var(--one-color-orange-600);
--one-color-chart-qualitative-orange-high: var(--one-color-orange-700);
--one-color-chart-qualitative-yellow-medium: var(--one-color-yellow-500);
--one-color-chart-qualitative-yellow-high: var(--one-color-yellow-700);
--one-color-chart-qualitative-magenta-medium: var(--one-color-magenta-600);
--one-color-chart-qualitative-magenta-high: var(--one-color-magenta-800);
--one-color-chart-qualitative-blue-medium: var(--one-color-chart-blue-600);
--one-color-chart-qualitative-blue-high: var(--one-color-chart-blue-800);
--one-color-chart-qualitative-red-medium: var(--one-color-red-500);
--one-color-chart-qualitative-red-high: var(--one-color-red-700);
--one-color-chart-qualitative-purple-medium: var(--one-color-purple-500);
--one-color-chart-qualitative-purple-high: var(--one-color-purple-800);
--one-color-background-base: var(--one-color-accent-white);
--one-color-background-intensity-lower: var(--one-color-gray-50);
--one-color-background-intensity-low: var(--one-color-gray-100);
--one-color-background-intensity-medium: var(--one-color-gray-300);
--one-color-background-intensity-high: var(--one-color-gray-700);
--one-color-background-brand-base: var(--one-color-blue-600);
--one-color-background-brand-lowest: var(--one-color-blue-50);
--one-color-background-brand-lower: var(--one-color-blue-100);
--one-color-background-brand-low: var(--one-color-blue-200);
--one-color-background-brand-higher: var(--one-color-blue-800);
--one-color-background-subbrand-base: var(--one-color-subbrand-600);
--one-color-background-subbrand-lower: var(--one-color-subbrand-200);
--one-color-background-positive-base: var(--one-color-green-500);
--one-color-background-positive-low: var(--one-color-green-200);
--one-color-background-warning-base: var(--one-color-yellow-400);
--one-color-background-warning-low: var(--one-color-yellow-100);
--one-color-background-negative-base: var(--one-color-red-400);
--one-color-background-negative-low: var(--one-color-red-100);
--one-color-background-contrast: var(--one-color-gray-200);
--one-color-background-overlay: var(--one-color-gray-0);
--one-color-foreground-base: var(--one-color-gray-900);
--one-color-foreground-intensity-lowest: var(--one-color-accent-white);
--one-color-foreground-intensity-low: var(--one-color-gray-200);
--one-color-foreground-intensity-medium: var(--one-color-gray-400);
--one-color-foreground-intensity-high: var(--one-color-gray-600);
--one-color-foreground-brand-base: var(--one-color-blue-600);
--one-color-foreground-brand-medium: var(--one-color-blue-500);
--one-color-foreground-brand-low: var(--one-color-blue-200);
--one-color-foreground-brand-high: var(--one-color-blue-800);
--one-color-foreground-subbrand-base: var(--one-color-subbrand-400);
--one-color-foreground-positive-base: var(--one-color-green-500);
--one-color-foreground-positive-low: var(--one-color-green-200);
--one-color-foreground-warning-base: var(--one-color-yellow-400);
--one-color-foreground-warning-low: var(--one-color-yellow-200);
--one-color-foreground-negative-base: var(--one-color-red-400);
--one-color-foreground-negative-low: var(--one-color-red-200);
--one-color-foreground-contrast-highest: var(--one-color-accent-white);
--one-color-foreground-contrast-lowest: var(--one-color-gray-900);
--one-color-interaction-default-base: var(--one-color-accent-white);
--one-color-interaction-default-intensity-lower: var(--one-color-gray-50);
--one-color-interaction-default-intensity-low: var(--one-color-gray-100);
--one-color-interaction-default-intensity-medium: var(--one-color-gray-600);
--one-color-interaction-default-intensity-higher: var(--one-color-gray-900);
--one-color-interaction-default-brand-base: var(--one-color-blue-600);
--one-color-interaction-default-positive-base: var(--one-color-green-500);
--one-color-interaction-default-positive-low: var(--one-color-green-200);
--one-color-interaction-default-warning-base: var(--one-color-yellow-400);
--one-color-interaction-default-warning-low: var(--one-color-yellow-200);
--one-color-interaction-default-negative-base: var(--one-color-red-400);
--one-color-interaction-default-negative-low: var(--one-color-red-200);
--one-color-interaction-default-contrast-higher: var(--one-color-gray-700);
--one-color-interaction-default-contrast-lowest: var(--one-color-accent-white);
--one-color-interaction-hover-base: var(--one-color-accent-white);
--one-color-interaction-hover-intensity-lower: var(--one-color-gray-50);
--one-color-interaction-hover-intensity-low: var(--one-color-gray-200);
--one-color-interaction-hover-intensity-medium: var(--one-color-gray-600);
--one-color-interaction-hover-intensity-higher: var(--one-color-gray-900);
--one-color-interaction-hover-brand-base: var(--one-color-blue-600);
--one-color-interaction-hover-brand-lower: var(--one-color-blue-200);
--one-color-interaction-hover-brand-low: var(--one-color-blue-300);
--one-color-interaction-hover-brand-high: var(--one-color-blue-700);
--one-color-interaction-hover-positive-base: var(--one-color-green-600);
--one-color-interaction-hover-positive-low: var(--one-color-green-100);
--one-color-interaction-hover-warning-base: var(--one-color-yellow-250);
--one-color-interaction-hover-warning-low: var(--one-color-yellow-100);
--one-color-interaction-hover-negative-base: var(--one-color-red-500);
--one-color-interaction-hover-negative-low: var(--one-color-red-100);
--one-color-interaction-hover-contrast-higher: var(--one-color-gray-900);
--one-color-interaction-hover-contrast-lower: var(--one-color-gray-50);
--one-color-interaction-focus-base: var(--one-color-accent-white);
--one-color-interaction-focus-intensity-lower: var(--one-color-gray-50);
--one-color-interaction-focus-intensity-low: var(--one-color-gray-100);
--one-color-interaction-focus-intensity-medium: var(--one-color-gray-600);
--one-color-interaction-focus-intensity-higher: var(--one-color-gray-900);
--one-color-interaction-focus-brand-base: var(--one-color-blue-600);
--one-color-interaction-focus-brand-lower: var(--one-color-blue-100);
--one-color-interaction-focus-brand-low: var(--one-color-blue-200);
--one-color-interaction-focus-brand-medium: var(--one-color-blue-300);
--one-color-interaction-focus-brand-high: var(--one-color-blue-700);
--one-color-interaction-focus-positive-base: var(--one-color-green-500);
--one-color-interaction-focus-positive-low: var(--one-color-green-200);
--one-color-interaction-focus-warning-base: var(--one-color-yellow-400);
--one-color-interaction-focus-warning-low: var(--one-color-yellow-200);
--one-color-interaction-focus-negative-base: var(--one-color-red-400);
--one-color-interaction-focus-negative-low: var(--one-color-red-200);
--one-color-interaction-focus-contrast-higher: var(--one-color-gray-900);
--one-color-interaction-focus-contrast-lower: var(--one-color-gray-50);
--one-color-interaction-active-base: var(--one-color-accent-white);
--one-color-interaction-active-intensity-lower: var(--one-color-gray-50);
--one-color-interaction-active-intensity-low: var(--one-color-gray-200);
--one-color-interaction-active-intensity-medium: var(--one-color-gray-600);
--one-color-interaction-active-intensity-higher: var(--one-color-gray-900);
--one-color-interaction-active-brand-base: var(--one-color-blue-600);
--one-color-interaction-active-brand-lower: var(--one-color-blue-100);
--one-color-interaction-active-brand-medium: var(--one-color-blue-300);
--one-color-interaction-active-brand-high: var(--one-color-blue-800);
--one-color-interaction-active-subbrand-base: var(--one-color-subbrand-600);
--one-color-interaction-active-subbrand-lower: var(--one-color-subbrand-100);
--one-color-interaction-active-positive-base: var(--one-color-green-800);
--one-color-interaction-active-positive-low: var(--one-color-green-300);
--one-color-interaction-active-warning-base: var(--one-color-yellow-200);
--one-color-interaction-active-warning-low: var(--one-color-yellow-400);
--one-color-interaction-active-negative-base: var(--one-color-red-700);
--one-color-interaction-active-negative-low: var(--one-color-red-200);
--one-color-interaction-active-contrast-higher: var(--one-color-gray-900);
--one-color-interaction-disabled-base: var(--one-color-gray-200);
--one-color-interaction-disabled-intensity-lowest: var(--one-color-accent-white);
--one-color-interaction-disabled-intensity-lower: var(--one-color-gray-50);
--one-color-interaction-disabled-intensity-low: var(--one-color-gray-100);
--one-color-interaction-disabled-intensity-medium: var(--one-color-gray-300);
--one-color-interaction-disabled-intensity-high: var(--one-color-gray-400);
--one-color-interaction-disabled-brand-base: var(--one-color-blue-300);
--one-color-interaction-disabled-contrast-high: var(--one-color-gray-500);
--one-color-accent-magenta-high: var(--one-color-magenta-800);
--one-color-accent-magenta-medium: var(--one-color-magenta-600);
--one-color-accent-magenta-low: var(--one-color-magenta-300);
--one-color-accent-magenta-lower: var(--one-color-magenta-100);
--one-color-accent-purple-high: var(--one-color-purple-800);
--one-color-accent-purple-medium: var(--one-color-purple-500);
--one-color-accent-purple-low: var(--one-color-purple-300);
--one-color-accent-purple-lower: var(--one-color-purple-100);
--one-color-accent-orange-high: var(--one-color-orange-700);
--one-color-accent-orange-medium: var(--one-color-orange-600);
--one-color-accent-orange-low: var(--one-color-orange-400);
--one-color-accent-orange-lower: var(--one-color-orange-250);
--one-color-accent-brown-lower: var(--one-color-neutral-300);
--one-color-accent-brown-low: var(--one-color-neutral-500);
--one-color-accent-brown-medium: var(--one-color-neutral-800);
--one-color-accent-brown-high: var(--one-color-neutral-900);
--one-spacing-gap-none: var(--one-spacing-base-none);
--one-spacing-gap-7-xs: var(--one-spacing-base-0-1);
--one-spacing-gap-6-xs: var(--one-spacing-base-0-2);
--one-spacing-gap-5-xs: var(--one-spacing-base-1);
--one-spacing-gap-4-xs: var(--one-spacing-base-2);
--one-spacing-gap-3-xs: var(--one-spacing-base-3);
--one-spacing-gap-2-xs: var(--one-spacing-base-4);
--one-spacing-gap-xs: var(--one-spacing-base-5);
--one-spacing-gap-s: var(--one-spacing-base-6);
--one-spacing-gap-m: var(--one-spacing-base-8);
--one-spacing-gap-l: var(--one-spacing-base-10);
--one-spacing-gap-xl: var(--one-spacing-base-12);
--one-spacing-gap-2-xl: var(--one-spacing-base-14);
--one-spacing-gap-3-xl: var(--one-spacing-base-16);
--one-spacing-gap-4-xl: var(--one-spacing-base-18);
--one-spacing-gap-5-xl: var(--one-spacing-base-20);
--one-spacing-gap-6-xl: var(--one-spacing-base-22);
--one-spacing-outline-none: var(--one-spacing-base-none);
--one-spacing-outline-regular: var(--one-spacing-base-0-1);
--one-spacing-outline-semibold: var(--one-spacing-base-0-2);
--one-spacing-outline-bold: var(--one-spacing-base-1);
--one-spacing-outline-extrabold: var(--one-spacing-base-2);
--one-spacing-padding-none: var(--one-spacing-base-none);
--one-spacing-padding-7-xs: var(--one-spacing-base-0-1);
--one-spacing-padding-6-xs: var(--one-spacing-base-0-2);
--one-spacing-padding-5-xs: var(--one-spacing-base-1);
--one-spacing-padding-4-xs: var(--one-spacing-base-2);
--one-spacing-padding-3-xs: var(--one-spacing-base-3);
--one-spacing-padding-2-xs: var(--one-spacing-base-4);
--one-spacing-padding-xs: var(--one-spacing-base-5);
--one-spacing-padding-s: var(--one-spacing-base-6);
--one-spacing-padding-m: var(--one-spacing-base-8);
--one-spacing-padding-l: var(--one-spacing-base-10);
--one-spacing-padding-xl: var(--one-spacing-base-12);
--one-spacing-padding-2-xl: var(--one-spacing-base-14);
--one-spacing-padding-3-xl: var(--one-spacing-base-16);
--one-spacing-padding-4-xl: var(--one-spacing-base-18);
--one-spacing-padding-5-xl: var(--one-spacing-base-20);
--one-spacing-padding-6-xl: var(--one-spacing-base-22);
--one-spacing-radius-none: var(--one-spacing-base-none);
--one-spacing-radius-7-xs: var(--one-spacing-base-0-1);
--one-spacing-radius-6-xs: var(--one-spacing-base-0-2);
--one-spacing-radius-5-xs: var(--one-spacing-base-1);
--one-spacing-radius-4-xs: var(--one-spacing-base-2);
--one-spacing-radius-3-xs: var(--one-spacing-base-3);
--one-spacing-radius-2-xs: var(--one-spacing-base-4);
--one-spacing-radius-xs: var(--one-spacing-base-5);
--one-spacing-radius-s: var(--one-spacing-base-6);
--one-spacing-radius-m: var(--one-spacing-base-8);
--one-spacing-radius-l: var(--one-spacing-base-10);
--one-spacing-radius-xl: var(--one-spacing-base-12);
--one-spacing-radius-2-xl: var(--one-spacing-base-14);
--one-spacing-radius-3-xl: var(--one-spacing-base-16);
--one-spacing-radius-4-xl: var(--one-spacing-base-18);
--one-spacing-radius-5-xl: var(--one-spacing-base-20);
--one-spacing-radius-6-xl: var(--one-spacing-base-22);
--one-spacing-size-none: var(--one-spacing-base-none);
--one-spacing-size-7-xs: var(--one-spacing-base-0-1);
--one-spacing-size-6-xs: var(--one-spacing-base-0-2);
--one-spacing-size-5-xs: var(--one-spacing-base-1);
--one-spacing-size-4-xs: var(--one-spacing-base-2);
--one-spacing-size-3-xs: var(--one-spacing-base-3);
--one-spacing-size-2-xs: var(--one-spacing-base-4);
--one-spacing-size-xs: var(--one-spacing-base-5);
--one-spacing-size-s: var(--one-spacing-base-6);
--one-spacing-size-m: var(--one-spacing-base-8);
--one-spacing-size-l: var(--one-spacing-base-10);
--one-spacing-size-xl: var(--one-spacing-base-12);
--one-spacing-size-2-xl: var(--one-spacing-base-14);
--one-spacing-size-3-xl: var(--one-spacing-base-16);
--one-spacing-size-4-xl: var(--one-spacing-base-18);
--one-spacing-size-5-xl: var(--one-spacing-base-20);
--one-spacing-size-6-xl: var(--one-spacing-base-22);
--one-text-font-weight-light: var(--one-text-font-weight-300);
--one-text-font-weight-regular: var(--one-text-font-weight-400);
--one-text-font-weight-medium: var(--one-text-font-weight-500);
--one-text-font-weight-bold: var(--one-text-font-weight-700);
--one-text-title-1-font-family: var(--one-text-font-family-default);
--one-text-title-1-font-weight: var(--one-text-font-weight-300);
--one-text-title-1-font-size: var(--one-text-font-size-px-96);
--one-text-title-1-line-height: var(--one-text-line-height-125);
--one-text-title-2-font-family: var(--one-text-font-family-default);
--one-text-title-2-font-weight: var(--one-text-font-weight-300);
--one-text-title-2-font-size: var(--one-text-font-size-px-60);
--one-text-title-2-line-height: var(--one-text-line-height-body);
--one-text-title-3-font-family: var(--one-text-font-family-default);
--one-text-title-3-font-weight: var(--one-text-font-weight-400);
--one-text-title-3-font-size: var(--one-text-font-size-px-48);
--one-text-title-3-line-height: var(--one-text-line-height-125);
--one-text-title-4-font-family: var(--one-text-font-family-default);
--one-text-title-4-font-weight: var(--one-text-font-weight-400);
--one-text-title-4-font-size: var(--one-text-font-size-px-34);
--one-text-title-4-line-height: var(--one-text-line-height-135);
--one-text-title-5-font-family: var(--one-text-font-family-default);
--one-text-title-5-font-weight: var(--one-text-font-weight-400);
--one-text-title-5-font-size: var(--one-text-font-size-px-24);
--one-text-title-5-line-height: var(--one-text-line-height-140);
--one-text-title-6-font-family: var(--one-text-font-family-default);
--one-text-title-6-font-weight: var(--one-text-font-weight-500);
--one-text-title-6-font-size: var(--one-text-font-size-px-20);
--one-text-title-6-line-height: var(--one-text-line-height-160);
--one-text-subtitle-1-font-family: var(--one-text-font-family-default);
--one-text-subtitle-1-font-weight: var(--one-text-font-weight-400);
--one-text-subtitle-1-font-size: var(--one-text-font-size-px-16);
--one-text-subtitle-1-line-height: var(--one-text-line-height-160);
--one-text-subtitle-2-font-family: var(--one-text-font-family-default);
--one-text-subtitle-2-font-weight: var(--one-text-font-weight-500);
--one-text-subtitle-2-font-size: var(--one-text-font-size-px-14);
--one-text-subtitle-2-line-height: var(--one-text-line-height-160);
--one-text-body-1-font-family: var(--one-text-font-family-default);
--one-text-body-1-font-weight: var(--one-text-font-weight-400);
--one-text-body-1-font-size: var(--one-text-font-size-px-16);
--one-text-body-1-line-height: var(--one-text-line-height-160);
--one-text-body-2-font-family: var(--one-text-font-family-default);
--one-text-body-2-font-weight: var(--one-text-font-weight-400);
--one-text-body-2-font-size: var(--one-text-font-size-px-14);
--one-text-body-2-line-height: var(--one-text-line-height-160);
--one-text-body-1-accent-font-family: var(--one-text-font-family-default);
--one-text-body-1-accent-font-weight: var(--one-text-font-weight-500);
--one-text-body-1-accent-font-size: var(--one-text-font-size-px-16);
--one-text-body-1-accent-line-height: var(--one-text-line-height-body);
--one-text-caption-accent-font-family: var(--one-text-font-family-default);
--one-text-caption-accent-font-weight: var(--one-text-font-weight-700);
--one-text-caption-accent-font-size: var(--one-text-font-size-px-12);
--one-text-caption-accent-line-height: var(--one-text-line-height-caption);
--one-text-caption-font-family: var(--one-text-font-family-default);
--one-text-caption-font-weight: var(--one-text-font-weight-400);
--one-text-caption-font-size: var(--one-text-font-size-px-12);
--one-text-caption-line-height: var(--one-text-line-height-165);
--one-typography-font-family: var(--one-text-font-family-roche-sans);
--one-typography-desktop-title-1-font-size: var(--one-text-font-size-base-25);
--one-typography-desktop-title-1-line-height: var(--one-text-line-height-base-24);
--one-typography-desktop-title-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-title-2-font-size: var(--one-text-font-size-base-20);
--one-typography-desktop-title-2-line-height: var(--one-text-line-height-base-20);
--one-typography-desktop-title-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-title-3-font-size: var(--one-text-font-size-base-18);
--one-typography-desktop-title-3-line-height: var(--one-text-line-height-base-18);
--one-typography-desktop-title-3-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-title-4-font-size: var(--one-text-font-size-base-13);
--one-typography-desktop-title-4-line-height: var(--one-text-line-height-base-16);
--one-typography-desktop-title-4-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-title-5-font-size: var(--one-text-font-size-base-8);
--one-typography-desktop-title-5-line-height: var(--one-text-line-height-base-9);
--one-typography-desktop-title-5-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-title-6-font-size: var(--one-text-font-size-base-6);
--one-typography-desktop-title-6-line-height: var(--one-text-line-height-base-9);
--one-typography-desktop-title-6-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-subtitle-1-font-size: var(--one-text-font-size-base-4);
--one-typography-desktop-subtitle-1-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-subtitle-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-subtitle-2-font-size: var(--one-text-font-size-base-3);
--one-typography-desktop-subtitle-2-line-height: var(--one-text-line-height-base-4);
--one-typography-desktop-subtitle-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-body-1-font-size: var(--one-text-font-size-base-4);
--one-typography-desktop-body-1-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-body-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-body-2-font-size: var(--one-text-font-size-base-3);
--one-typography-desktop-body-2-line-height: var(--one-text-line-height-base-4);
--one-typography-desktop-body-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-button-font-size: var(--one-text-font-size-base-4);
--one-typography-desktop-button-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-button-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-badge-font-size: var(--one-text-font-size-base-2);
--one-typography-desktop-badge-line-height: var(--one-text-line-height-base-1);
--one-typography-desktop-badge-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-caption-font-size: var(--one-text-font-size-base-2);
--one-typography-desktop-caption-line-height: var(--one-text-line-height-base-1);
--one-typography-desktop-caption-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-overline-font-size: var(--one-text-font-size-base-1);
--one-typography-desktop-overline-line-height: var(--one-text-line-height-base-1);
--one-typography-desktop-overline-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-tab-font-size: var(--one-text-font-size-base-6);
--one-typography-desktop-tab-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-tab-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-subheader-font-size: var(--one-text-font-size-base-5);
--one-typography-desktop-subheader-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-subheader-font-kerning: var(--one-text-font-kerning-none);
--one-typography-desktop-eyebrow-font-size: var(--one-text-font-size-base-5);
--one-typography-desktop-eyebrow-line-height: var(--one-text-line-height-base-5);
--one-typography-desktop-eyebrow-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-1-font-size: var(--one-text-font-size-rem-4);
--one-typography-mobile-title-1-line-height: var(--one-text-line-height-rem-4-625);
--one-typography-mobile-title-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-2-font-size: var(--one-text-font-size-rem-2-5);
--one-typography-mobile-title-2-line-height: var(--one-text-line-height-rem-2-75);
--one-typography-mobile-title-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-3-font-size: var(--one-text-font-size-rem-2);
--one-typography-mobile-title-3-line-height: var(--one-text-line-height-rem-2-375);
--one-typography-mobile-title-3-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-4-font-size: var(--one-text-font-size-rem-1-5);
--one-typography-mobile-title-4-line-height: var(--one-text-line-height-rem-2);
--one-typography-mobile-title-4-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-5-font-size: var(--one-text-font-size-rem-1-125);
--one-typography-mobile-title-5-line-height: var(--one-text-line-height-rem-1-625);
--one-typography-mobile-title-5-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-title-6-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-title-6-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-mobile-title-6-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-subtitle-1-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-subtitle-1-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-mobile-subtitle-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-subtitle-2-font-size: var(--one-text-font-size-rem-0-875);
--one-typography-mobile-subtitle-2-line-height: var(--one-text-line-height-rem-1-25);
--one-typography-mobile-subtitle-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-body-1-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-body-1-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-mobile-body-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-body-2-font-size: var(--one-text-font-size-rem-0-875);
--one-typography-mobile-body-2-line-height: var(--one-text-line-height-rem-1-25);
--one-typography-mobile-body-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-button-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-button-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-mobile-button-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-badge-font-size: var(--one-text-font-size-rem-0-75);
--one-typography-mobile-badge-line-height: var(--one-text-line-height-rem-1);
--one-typography-mobile-badge-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-caption-font-size: var(--one-text-font-size-rem-0-75);
--one-typography-mobile-caption-line-height: var(--one-text-line-height-rem-1);
--one-typography-mobile-caption-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-overline-font-size: var(--one-text-font-size-rem-0-625);
--one-typography-mobile-overline-line-height: var(--one-text-line-height-rem-1);
--one-typography-mobile-overline-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-tab-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-tab-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-mobile-tab-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-subheader-font-size: var(--one-text-font-size-rem-1);
--one-typography-mobile-subheader-line-height: var(--one-text-line-height-rem-1-375);
--one-typography-mobile-subheader-font-kerning: var(--one-text-font-kerning-none);
--one-typography-mobile-eyebrow-font-size: var(--one-text-font-size-rem-1-125);
--one-typography-mobile-eyebrow-line-height: var(--one-text-line-height-rem-1-625);
--one-typography-mobile-eyebrow-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-1-font-size: var(--one-text-font-size-rem-5-5);
--one-typography-tablet-title-1-line-height: var(--one-text-line-height-rem-6-625);
--one-typography-tablet-title-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-2-font-size: var(--one-text-font-size-rem-3-5);
--one-typography-tablet-title-2-line-height: var(--one-text-line-height-rem-4);
--one-typography-tablet-title-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-3-font-size: var(--one-text-font-size-rem-2-5);
--one-typography-tablet-title-3-line-height: var(--one-text-line-height-rem-3-25);
--one-typography-tablet-title-3-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-4-font-size: var(--one-text-font-size-rem-2);
--one-typography-tablet-title-4-line-height: var(--one-text-line-height-rem-2-625);
--one-typography-tablet-title-4-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-5-font-size: var(--one-text-font-size-rem-1-5);
--one-typography-tablet-title-5-line-height: var(--one-text-line-height-rem-2-125);
--one-typography-tablet-title-5-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-title-6-font-size: var(--one-text-font-size-rem-1-125);
--one-typography-tablet-title-6-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-tablet-title-6-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-subtitle-1-font-size: var(--one-text-font-size-rem-1);
--one-typography-tablet-subtitle-1-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-tablet-subtitle-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-subtitle-2-font-size: var(--one-text-font-size-rem-0-875);
--one-typography-tablet-subtitle-2-line-height: var(--one-text-line-height-rem-1-25);
--one-typography-tablet-subtitle-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-body-1-font-size: var(--one-text-font-size-rem-1);
--one-typography-tablet-body-1-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-tablet-body-1-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-body-2-font-size: var(--one-text-font-size-rem-0-875);
--one-typography-tablet-body-2-line-height: var(--one-text-line-height-rem-1-25);
--one-typography-tablet-body-2-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-button-font-size: var(--one-text-font-size-rem-1);
--one-typography-tablet-button-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-tablet-button-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-badge-font-size: var(--one-text-font-size-rem-0-75);
--one-typography-tablet-badge-line-height: var(--one-text-line-height-rem-1);
--one-typography-tablet-badge-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-caption-font-size: var(--one-text-font-size-rem-0-75);
--one-typography-tablet-caption-line-height: var(--one-text-line-height-rem-1);
--one-typography-tablet-caption-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-overline-font-size: var(--one-text-font-size-rem-0-625);
--one-typography-tablet-overline-line-height: var(--one-text-line-height-rem-1);
--one-typography-tablet-overline-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-tab-font-size: var(--one-text-font-size-rem-1-25);
--one-typography-tablet-tab-line-height: var(--one-text-line-height-rem-1-5);
--one-typography-tablet-tab-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-subheader-font-size: var(--one-text-font-size-rem-1);
--one-typography-tablet-subheader-line-height: var(--one-text-line-height-rem-1-375);
--one-typography-tablet-subheader-font-kerning: var(--one-text-font-kerning-none);
--one-typography-tablet-eyebrow-font-size: var(--one-text-font-size-rem-1-125);
--one-typography-tablet-eyebrow-line-height: var(--one-text-line-height-rem-1-625);
--one-typography-tablet-eyebrow-font-kerning: var(--one-text-font-kerning-none);
--one-banner-color-secondary-info-icon: var(--one-color-background-base);
--one-banner-color-secondary-positive-icon: var(--one-color-background-base);
--one-banner-color-primary-default-background: var(--one-color-background-intensity-lower);
--one-banner-color-primary-positive-background: var(--one-color-background-positive-low);
--one-banner-color-primary-info-background: var(--one-color-background-intensity-lower);
--one-banner-color-primary-warning-background: var(--one-color-background-warning-low);
--one-banner-color-primary-alert-background: var(--one-color-background-negative-low);
--one-button-padding-medium: var(--one-spacing-padding-4-xs) var(--one-spacing-padding-2-xs);
--one-button-padding-small: var(--one-spacing-padding-5-xs) var(--one-spacing-padding-4-xs);
--one-contact-info-color-address-background: var(--one-color-background-base);
--one-contact-info-color-address-border-top: var(--one-color-background-subbrand-base);
--one-header-row-box-shadow-default-top: var(--one-box-shadow-inset) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-2) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-0) var(--one-color-foreground-subbrand-base);
--one-header-row-box-shadow-extended-top: var(--one-box-shadow-inset) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-4) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-0) var(--one-color-foreground-subbrand-base);
--one-progress-bar-color-primary-positive-foreground: var(--one-color-foreground-subbrand-base);
--one-progress-bar-color-secondary-positive-foreground: var(--one-color-foreground-subbrand-base);
--one-virtual-keyboard-background-color: var(--one-color-background-intensity-lower);
--one-footer-box-shadow-top: var(--one-box-shadow-inset) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-4) var(--one-box-shadow-positive-0) var(--one-box-shadow-positive-0) var(--one-color-foreground-subbrand-base);
--one-footer-color-default-background: var(--one-color-background-intensity-lower);
--one-footer-color-default-foreground: var(--one-color-foreground-base);
--one-footer-color-global-foreground: var(--one-color-foreground-base);
}